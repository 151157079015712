import store from "@/store";
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import BasicLayout from "../layouts/BasicLayout.vue";
import RouteView from "../layouts/RouteView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/",
    name: "index",
    meta: { title: "PT管理宝" },
    component: BasicLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: { title: "首页", icon: "dashboard-outlined" },
        component: () => import("../views/Index.vue"),
      },
      {
        path: "/pt-cookie",
        name: "PtCookie",
        meta: { title: "站点Cookie", icon: "api-outlined" },
        component: () => import("../views/PtCookie.vue"),
      },
      {
        path: "/pt-manage-key",
        name: "PtManageKey",
        meta: { title: "外部访问链接", icon: "key-outlined" },
        component: () => import("../views/PtManageKey.vue"),
      },
      {
        path: "/set-password",
        name: "SetPassword",
        meta: { title: "修改密码", icon: "lock-outlined" },
        component: () => import("../views/SetPassword.vue"),
      },
      // {
      //   path: "/page2",
      //   name: "page2",
      //   meta: { title: "Page2", icon: "desktop-outlined" },
      //   component: () => import("../views/Page2.vue"),
      // },
      // {
      //   path: "/dashboard",
      //   name: "dashboard",
      //   meta: { title: "Dashboard", icon: "DashboardOutlined" },
      //   redirect: "/dashboard/monitor",
      //   component: RouteView,
      //   children: [
      //     {
      //       path: "workspace",
      //       name: "workspace",
      //       meta: { title: "Workspace", icon: "icon-antdesign" },
      //       component: () => import("../views/Page1.vue"),
      //     },
      //     {
      //       path: "monitor",
      //       name: "monitor",
      //       meta: { title: "Monitor", icon: "icon-icon-test" },
      //       component: () => import("../views/Page2.vue"),
      //     },
      //   ],
      // },
      // {
      //   path: "/form",
      //   name: "form",
      //   meta: { title: "Form", icon: "FormOutlined" },
      //   redirect: "/form/basic-form",
      //   component: RouteView,
      //   children: [
      //     {
      //       path: "basic-form",
      //       name: "basic-form",
      //       meta: { title: "Basic Form" },
      //       component: () => import("../views/Page1.vue"),
      //     },
      //   ],
      // },
      // {
      //   path: "/single",
      //   name: "single",
      //   meta: { title: "Single", icon: "SettingOutlined" },
      //   component: () => import("../views/Page2.vue"),
      // },
    ],
  },
  {
    path: "/:w+",
    name: "*",
    redirect: "/",
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach(async (to, from, next) => {
  if (to.name !== "Login") {
    const token = await store.dispatch("GetToken");
    if (!token) return next({ name: "Login" });
  }
  next();
});
export default router;
