import { IUserInfo, UrlType } from "@/interface";
import request, { MyResponseType } from "./http";

export interface ILoginParams {
  username: string;
  password: string;
}

export interface ILoginRespone {
  token: string;
}

export const loginRequest = (
  data: ILoginParams
): Promise<MyResponseType<ILoginRespone>> => {
  return request<ILoginRespone>({
    method: "POST",
    url: "/admin/login",
    data,
  });
};

export interface IChangePwdParams {
  oldPassword: string;
  newPassword: string;
}

export const changePwdRequest = (
  data: IChangePwdParams
): Promise<MyResponseType<null>> => {
  return request({
    method: "POST",
    url: "/admin/user/changePassword",
    data,
  });
};

export const getPtManageKey = (): Promise<MyResponseType<string>> => {
  return request({
    method: "GET",
    url: "/admin/pt/getPtManageKey",
  });
};

export const setPtManageKey = (key: string): Promise<MyResponseType<null>> => {
  return request({
    method: "POST",
    url: "/admin/pt/setPtManageKey",
    data: {
      value: key,
    },
  });
};

export interface IStation {
  id: number;
  code: string;
  cookie: string;
  remark: string;
  icon?: string;
}

export interface IStationOption {
  name: string;
  code: string;
  host: string;
  url: string;
  icon?: string;
}

export const getStationOptions = (): Promise<
  MyResponseType<IStationOption[]>
> => {
  return request({
    method: "GET",
    url: "/admin/station/options",
  });
};

export const getStationList = (): Promise<MyResponseType<IStation[]>> => {
  return request({
    method: "GET",
    url: "/admin/station/list",
  });
};

export const createStation = (
  data: Omit<IStation, "id">
): Promise<MyResponseType<IStation>> => {
  return request({
    method: "POST",
    url: "/admin/station/create",
    data,
  });
};

export const editStation = (
  data: IStation
): Promise<MyResponseType<IStation>> => {
  return request({
    method: "POST",
    url: "/admin/station/edit",
    data,
  });
};

export const removeStation = (id: number): Promise<MyResponseType<null>> => {
  return request({
    method: "POST",
    url: "/admin/station/remove",
    data: {
      id,
    },
  });
};

export const getUserInfo = (): Promise<MyResponseType<IUserInfo>> => {
  return request({
    method: "GET",
    url: "/admin/user/getUserInfo",
  });
};

export const setUrl = (
  remoteUrl: string,
  remoteUrlType: UrlType,
  localUrl: string
): Promise<MyResponseType<null>> => {
  return request({
    method: "POST",
    url: "/admin/user/setUrl",
    data: {
      remoteUrl,
      remoteUrlType,
      localUrl,
    },
  });
};

export const getBindCode = (): Promise<MyResponseType<string>> => {
  return request({
    method: "GET",
    url: "/admin/user/getBindCode",
  });
};

export const setStationSort = (
  ids: number[]
): Promise<MyResponseType<boolean>> => {
  return request({
    method: "POST",
    url: "/admin/station/sort",
    data: {
      ids,
    },
  });
};

export const importStation = (
  data: Omit<IStation, "id">[]
): Promise<MyResponseType<boolean>> => {
  return request({
    method: "POST",
    url: "/admin/station/importStation",
    data: {
      importData: data,
    },
  });
};

export const checkCookies = (
  url: string,
  cookie: string
): Promise<MyResponseType<boolean>> => {
  return request({
    method: "POST",
    url: "/admin/station/checkCookies",
    data: {
      url,
      cookie,
    },
    timeout: 15000,
  });
};
