import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd, { message, notification } from "ant-design-vue";
import ProLayout, { PageContainer } from "@ant-design-vue/pro-layout";

import "ant-design-vue/dist/antd.less";
import "@ant-design-vue/pro-layout/dist/style.css"; // pro-layout css or style.less
import "./global.less"; // global style
import * as Icons from "@ant-design/icons-vue";
const app = createApp(App)
  .use(store)
  .use(router)
  .use(Antd)
  .use(ProLayout)
  .use(PageContainer);
app.config.globalProperties.$message = message;
app.config.globalProperties.$notification = notification;
app.mount("#app");

const icons: any = Icons;
for (const i in icons) {
  app.component(i, icons[i]);
}
