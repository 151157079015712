import { IState } from "@/interface";
import router from "@/router";
import { getUserInfo } from "@/service/common";
import { notification } from "ant-design-vue";
import { createStore } from "vuex";

export default createStore<IState>({
  state: {
    userInfo: null,
  },
  mutations: {
    SET_USERINFO(state, data) {
      state.userInfo = data;
      localStorage.setItem("PtUserInfo", JSON.stringify(data));
    },
  },
  actions: {
    async Logout() {
      localStorage.removeItem("pt-manage-token");
      notification.success({
        message: "已注销，需要重新登录！",
      });
      router.replace({ name: "Login" });
    },
    SetToken(store, token: string) {
      localStorage.setItem("pt-manage-token", token);
    },
    ClearToken() {
      localStorage.removeItem("pt-manage-token");
    },
    GetToken() {
      return localStorage.getItem("pt-manage-token");
    },
    async GetUserInfo({ commit }) {
      const result = await getUserInfo();
      if (result.code === 200) {
        commit("SET_USERINFO", result.data);
        return result.data;
      } else {
        throw new Error(result.message);
      }
    },
  },
  modules: {},
});
