
import {
  computed,
  defineComponent,
  reactive,
  ref,
  watchEffect,
  onMounted,
} from "vue";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import {
  getMenuData,
  clearMenuItem,
  WaterMark,
  FooterToolbar,
} from "@ant-design-vue/pro-layout";
import type { RouteContextProps } from "@ant-design-vue/pro-layout";
import store from "@/store";
const i18n = (t: string) => t;
export default defineComponent({
  name: "BasicLayout",
  components: {
    FooterToolbar,
    WaterMark,
  },
  setup() {
    const loading = ref(false);
    const watermarkContent = ref("Pro Layout");
    const router = useRouter();
    const { menuData } = getMenuData(clearMenuItem(router.getRoutes()));
    const baseState = reactive<Omit<RouteContextProps, "menuData">>({
      selectedKeys: [],
      openKeys: [],
      // default
      collapsed: false,
    });
    const state = reactive({
      menuData,
      splitMenus: true,
      // title: 'ProLayout',
      // logo: 'https://alicdn.antdv.com/v2/assets/logo.1ef800a8.svg',
      navTheme: "dark",
      layout: "top",
      fixSiderbar: true,
      fixedHeader: true,
    });
    const breadcrumb = computed(() =>
      router.currentRoute.value.matched.concat().map((item) => {
        return {
          path: item.path,
          breadcrumbName: item.meta.title || "",
        };
      })
    );
    const handleCollapsed = () => {
      baseState.collapsed = !baseState.collapsed;
    };
    watchEffect(() => {
      if (router.currentRoute) {
        const matched = router.currentRoute.value.matched.concat();
        baseState.selectedKeys = matched
          .filter((r) => r.name !== "index")
          .map((r) => r.path);
        baseState.openKeys = matched
          .filter((r) => r.path !== router.currentRoute.value.path)
          .map((r) => r.path);
      }
    });
    function handlePageLoadingClick() {
      loading.value = true;
      setTimeout(() => {
        loading.value = false;
      }, 2000);
    }
    onMounted(() => {
      setTimeout(() => {
        watermarkContent.value = "New Mark";
      }, 2000);
    });
    const logoutHandle = async () => {
      await store.dispatch("Logout");
    };
    return {
      i18n,
      watermarkContent,
      baseState,
      state,
      loading,
      breadcrumb,
      handlePageLoadingClick,
      handleCollapsed,
      handleSearch: () => {
        message.info("search..");
      },
      logoutHandle,
    };
  },
});
