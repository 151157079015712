import store from "@/store";
import { message } from "ant-design-vue";
import axios, { AxiosRequestConfig } from "axios";
import router from "@/router";

export interface MyResponseType<T = any> {
  code: number;
  message: string;
  data: T;
}

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 60000,
});
instance.interceptors.request.use(async (config) => {
  const token = await store.dispatch("GetToken");
  const noAuthApi = ["/admin/login"];
  if (config.url && !noAuthApi.includes(config.url)) {
    if (!token) {
      router.replace({ name: "Login" });
      return false;
    } else {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
  }
  return config;
});

const request = async <T = any>(
  config: AxiosRequestConfig
): Promise<MyResponseType<T>> => {
  const speicalCode = [201, 202]; // 201密码错误，202token失效
  try {
    const { data } = await instance.request<MyResponseType<T>>(config);
    data.code === 200
      ? console.log(data.message) // 成功消息提示
      : console.error(data.message); // 失败消息提示
    if (data.code !== 200) {
      if (!speicalCode.includes(data.code)) {
        message.error(data.message);
      } else if (data.code === 202) {
        message.error(data.message);
        router.replace({ name: "Login" });
      }
    }
    return data;
  } catch (err: any) {
    const msg = err?.message || "请求失败";
    console.error(msg); // 失败消息提示
    message.error(msg);
    return {
      code: -1,
      message: msg,
      data: null as any,
    };
  }
};

export default request;
