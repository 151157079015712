
import { defineComponent } from "vue";
export default defineComponent({
  name: "App",
  setup() {
    const getPopupContainer = (el: Element, dialogContext: any) => {
      if (dialogContext) {
        return dialogContext.getDialogWrap();
      }
      return document.body;
    };
    return {
      getPopupContainer,
    };
  },
});
